import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@core/services/http/base-http.service';
import { OrganizationPlatformResource } from '@core/data/model/organization-platform-resource';
import { PlatformResourceConnectionPatchDto } from '@core/data/model/dto/platform-resource-connection-patch.dto';

@Injectable()
export class PlatformWebhookService extends BaseHttpService {

  constructor(protected override httpClient: HttpClient) {
    super('integration-service/platform-webhooks/resources', httpClient);
  }

  updatePlatformResourceConnection(resourceId: string, connect: boolean): Observable<any> {
    return this.patch<OrganizationPlatformResource>(`/${resourceId}`, new PlatformResourceConnectionPatchDto(connect));
  }
}
